<template>
  <div>
    <bread-crumb
      :name="shopCategory.name"
      :is-pharmacy="
        shopCategory.slug === 'pharmacies-health-care' && auth.isAuthenticated
      "
    ></bread-crumb>
    <div class="ps-page--shop">
      <div class="ps-container">
        <div class="ps-shop-categories"></div>
        <div class="ps-layout--shop">
          <div class="ps-layout__left">
            <aside class="mb-3">
              <img src="/img/greenwood-steps (2).jpg" alt="Greenwood" />
            </aside>

            <aside class="widget widget_shop">
              <h4 class="widget-title">Categories</h4>
              <ul class="ps-list--categories">
                <li
                  v-for="category in shopCategory.categories"
                  :key="category.id"
                  class="menu-item-has-children row justify-content-between pl-4 pr-4"
                >
                  {{ category.name }}
                  <input
                    class="form-check"
                    type="checkbox"
                    :id="'filter_category_' + category.id"
                    @change="updateCategoryFilters(category.id)"
                    name="filter_category"
                  />
                </li>
              </ul>
            </aside>
          </div>
          <div class="ps-layout__right">
            <div class="ps-shopping ps-tab-root">
              <div class="ps-shopping__header">
                <p>
                  <strong> {{ products.length }}</strong> of
                  <strong>{{ count }}</strong> products
                </p>
                <div class="ps-shopping__actions">
                  <div class="ps-shopping__view">
                    <p>View</p>
                    <ul class="ps-tab-list">
                      <li class="active">
                        <a href="#tab-1"><i class="icon-grid"></i></a>
                      </li>
                      <li>
                        <a href="#tab-2"><i class="icon-list4"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="ps-tabs">
                <b-overlay :show="isLoading" rounded="sm"></b-overlay>
                <div class="ps-tab active" id="tab-1">
                  <div v-if="!isLoading" class="ps-shopping-product">
                    <div class="row">
                      <div
                        v-for="product in products"
                        :key="product.id"
                        class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6"
                      >
                        <product-card :product="product"></product-card>
                      </div>
                    </div>
                  </div>
                  <div class="ps-pagination">
                    <ul class="pagination">
                      <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                        <a href="#"
                          >Prev Page<i class="icon-chevron-left"></i
                        ></a>
                      </li>
                      <li class="active">
                        <a href="#">Page {{ pageNumber }}</a>
                      </li>
                      <!--                      <li><a href="#">3</a></li>-->
                      <li v-on:click="goToNextPage()" v-if="hasNextPage">
                        <a href="#"
                          >Next Page<i class="icon-chevron-right"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="ps-tab" id="tab-2">
                  <div class="ps-shopping-product">
                    <list-product-card
                      v-for="product in products"
                      :key="product.id"
                      :product="product"
                    ></list-product-card>
                  </div>
                  <div class="ps-pagination" v-if="!isLoading">
                    <ul class="pagination">
                      <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                        <a href="#"
                          >Prev Page<i class="icon-chevron-left"></i
                        ></a>
                      </li>
                      <li class="active">
                        <a href="#">Page {{ pageNumber }}</a>
                      </li>
                      <!--                      <li><a href="#">3</a></li>-->
                      <li v-on:click="goToNextPage()" v-if="hasNextPage">
                        <a href="#"
                          >Next Page<i class="icon-chevron-right"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="shop-filter-lastest" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="list-group">
                  <a class="list-group-item list-group-item-action" href="#"
                    >Sort by</a
                  >
                  <a class="list-group-item list-group-item-action" href="#"
                    >Sort by average rating</a
                  >
                  <a class="list-group-item list-group-item-action" href="#"
                    >Sort by latest</a
                  >
                  <a class="list-group-item list-group-item-action" href="#"
                    >Sort by price: low to high</a
                  >
                  <a class="list-group-item list-group-item-action" href="#"
                    >Sort by price: high to low</a
                  >
                  <a
                    class="list-group-item list-group-item-action text-center"
                    href="#"
                    data-dismiss="modal"
                    ><strong>Close</strong></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadCrumb from "../../components/Navs/BreadCrumb.vue";
import ShopCategoryCard from "../../components/Cards/ShopCategoryCard.vue";
import ProductCard from "../../components/Cards/ProductCard.vue";
import ListProductCard from "../../components/Cards/ListProductCard.vue";
import { mapState } from "vuex";
export default {
  components: { BreadCrumb, ShopCategoryCard, ListProductCard, ProductCard },
  data() {
    return {
      selectedCategory: "All",
      products: [],
      shopCategory: {},
      links: {},
      meta: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
      selectedCategories: [],
      isLoading: false,
      categoryFilters: [],
      catFilterString: "",
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
  },
  mounted() {
    this.getCategoryProducts();
    this.getShopCategory();
  },
  methods: {
    updateCategoryFilters(cat_id) {
      var cat_index = this.categoryFilters.indexOf(cat_id);
      if (cat_index > -1) {
        //It exists in the array
        //Remove it from the array
        this.categoryFilters.splice(cat_index, 1);
      } else {
        // It doesnt exist in the array
        // Adde the item to the array
        this.categoryFilters.push(cat_id);
      }

      // Update filters string
      this.catFilterString = "";
      this.categoryFilters.forEach((item, index) => {
        this.catFilterString = this.catFilterString + item;
        if (index !== this.categoryFilters.length - 1) {
          this.catFilterString = this.catFilterString + "|";
        }
      });
      alert(this.catFilterString);
    },
    addCategoryFilter(categoryId) {
      alert(categoryId);
      if (this.selectedCategories.indexOf(categoryId) >= 0) {
        this.selectedCategories = this.selectedCategories.filter(
          (catId) => categoryId !== catId
        );
      } else {
        this.selectedCategories.push(categoryId);
      }
      this.getCategoryProducts();
    },

    selectCategory(name) {
      this.selectedCategory = name;
    },

    getCategoryProducts() {
      let queryParams = [];
      if (this.$route.params.slug) {
        queryParams.push("shop_category=" + this.$route.params.slug);
      }
      if (this.selectedCategories.length > 0) {
        queryParams.push("categories=" + this.selectedCategories.join(","));
      }
      let url = "products";
      if (this.url) {
        url = this.url;
      }

      if (queryParams.length > 0) {
        if (url.indexOf("?") < 0) {
          url = url + "?" + queryParams.join("&");
        } else {
          url = url + "&" + queryParams.join("&");
        }
      }
      this.isLoading = true;
      axios
        .get(url)
        .then((response) => {
          this.products = response.data.data;
          this.links = response.data.links;
          this.meta = response.data.meta;
          this.count = response.data.meta.total;
          this.pageNumber = response.data.meta.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    getShopCategory() {
      axios
        .get(`shops/category/${this.$route.params.slug}`)
        .then((response) => {
          this.shopCategory = response.data;
        });
    },

    addToCart(product, prop) {
      let newProp = {
        // color: prop.color,
        // size: prop.size,
        qty: 1,
      };
      if (product.properties.hasOwnProperty("color")) {
        newProp["color"] = product.properties.color;
      }
      if (product.properties.hasOwnProperty("size")) {
        newProp["size"] = product.properties.size;
      }
      this.add(product, newProp);
    },

    scrollTop() {
      let element = document.getElementsByClassName("ps-shopping-product");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },
    goToNextPage: function () {
      this.url = this.nextPage;
      if (this.url) {
        this.getCategoryProducts();
      }
      this.scrollTop();
    },

    goToPrevPage: function () {
      this.url = this.prevPage;
      if (this.url) {
        this.getCategoryProducts();
      }
      this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false;
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.sidebar {
  height: 700px;
}
.card-title {
  color: black;
}
.price {
  margin-left: 40px;
}

.card-frame > .card-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 5px;
}

.card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 5rem 6rem;
  background-color: #F1F1F1; */
}

@media screen and (max-width: 768px) {
  .card-section {
    padding: 3rem 3rem;
  }

  .search-input {
    width: 500px;
  }
}

@media screen and (max-width: 575px) {
  .card-section {
    padding: 0rem;
    padding-top: 4rem;
  }
  .search-input {
    width: 500px;
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .card-container {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 575px) {
  .card-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    row-gap: 0.8rem;
  }
}

.card-frame {
  height: 25rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

@media screen and (max-width: 575px) {
  .card-frame {
    height: 10rem;
  }
}

.card-frame[card="fast"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/fast_food.webp");
}

.card-frame[card="fast"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.6);
}

.card-frame[card="pharmacy"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/pharmacy.webp");
}

.card-frame[card="pharmacy"] > .card-overlay {
  background-color: rgba(60, 3, 70, 0.6);
}

.card-frame[card="electronics"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/electronics.webp");
}

.card-frame[card="electronics"] > .card-overlay {
  background-color: rgba(13, 70, 3, 0.6);
}

.card-frame[card="grocery"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/grocery.webp");
}

.card-frame[card="grocery"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hair_beauty"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hair_beauty.webp");
}

.card-frame[card="hair_beauty"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.5);
}

.card-frame[card="house_home"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/house.webp");
}

.card-frame[card="house_home"] > .card-overlay {
  background-color: rgba(38, 95, 109, 0.6);
}

.card-frame[card="construction"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/construction.webp");
}

.card-frame[card="construction"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hardware"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hardware.webp");
}

.card-frame[card="hardware"] > .card-overlay {
  background-color: rgba(6, 69, 153, 0.6);
}

.card-frame[card="vehicles"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/vehicles.webp");
}

.card-frame[card="vehicles"] > .card-overlay {
  background-color: rgba(211, 42, 42, 0.6);
}

.card-frame[card="solar"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/solar.webp");
}

.card-frame[card="solar"] > .card-overlay {
  background-color: rgba(23, 167, 131, 0.6);
}

.card-frame[card="entertainment"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/entertainment.webp");
}

.card-frame[card="entertainment"] > .card-overlay {
  background-color: rgba(238, 48, 136, 0.6);
}

.card-frame[card="funeral"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/funeral.webp");
}

.card-frame[card="funeral"] > .card-overlay {
  background-color: rgba(63, 68, 65, 0.6);
}

.card-frame[card="gifts"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/treats.webp");
}

.card-frame[card="gifts"] > .card-overlay {
  background-color: rgba(201, 58, 77, 0.6);
}

.card-heading {
  font-size: 1.7em;
  text-align: center;
  padding: 2.5rem 0;
  padding-top: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .card-heading {
    padding: 1.5rem 0;
  }
}

.card-title {
  color: #ffffff;
  font-size: 1.2em !important;
  margin: 0;
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 575px) {
  .card-title {
    font-size: 1em;
  }
}

.card-text {
  padding: 2rem;
  padding-right: 0.5rem;
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
}

.card-text > p {
  margin: 0;
  padding: 0;
  font-size: 0.96em;
  margin: auto 0;
  color: #fff;
}

@media screen and (max-width: 575px) {
  .card-text > p {
    display: none;
  }
}

.card-text > .btn-primary {
  padding: 10px;
  min-width: 130px;
  font-size: 15px;
}

@media screen and (max-width: 575px) {
  .card-text {
    flex: 1;
    padding: 0.7rem 1rem;
    justify-content: space-between;
  }
}

.card-logo {
  background-color: #fff;
  position: relative;
  flex: 1;
  -webkit-clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  height: 100%;
}

.card-logo > img {
  max-width: 60%;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

@media screen and (max-width: 575px) {
  .card-logo {
    display: none;
  }
}

.card-paragraph {
  padding: 0 !important;
}
</style>
